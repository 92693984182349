import React, {  useState } from "react";

import { requestPassword } from "../_redux/authCrud";

import { useForm } from 'react-hook-form';
import { NavLink } from "react-router-dom";
import {toastr} from 'react-redux-toastr'
import { EMAIL_REGEX } from '../../apiUrl'
import Header from '../../../../layout/header/header';
import Footer from '../../../../layout/footer/footer';



function ForgotPassword(props) {

    let [values,setState] = useState({
        email:"",
        loading:false,
        showModel:true,
        disableButton:false,
        isRequested:false
    })

    let hideModel = function(){
        setState({
            ...values,
            showModel:false
        })
        redirect()
    }

    let redirect = function(){
        props.history.push('/')
    }


    const {
        register,
        handleSubmit,
        getValues,
        watch,
        formState: { errors },
      } = useForm();

    const showHideLoader = (loading) =>{
        setState({
            ...values,
            loading:loading
        })
    }

    let onSubmit = handleSubmit((formdata) => {
        showHideLoader(true);
        // formdata.isRequested = values.isRequested //addign value that check password request check
        requestPassword(formdata).then((response) => {
            let { data } = response
            showHideLoader(false);
            if(response.status == 200){
                // console.log('data',data)
                toastr.success('Success',data.message);
                localStorage.setItem('isPasswordRequested',JSON.stringify({isRequested:true,email:formdata.email}))
                // props.resetPassword({isRequested:true,email:formdata.email});

                props.history.push(`/auth/verify-otp?email=${formdata.email}`)

            }else{
                toastr.error('Error',data.message);
                return;
            }
        }).catch((error) => {
            console.log('error',error)
            showHideLoader(false);
            toastr.error('Error',"Something went wrong!");
            return;
        });
    });


    return (
        <>
            <Header />
            <div className="page-block">
                <div className="container">
                    <div className="row">
                        <div className="col-md-6">
                            <img className="img-fluid logoclass" src="/media/images/logo2.png"/>
                            <p className="login_head">Welcome to FindMech</p>
                            <div className="card-body">
                                <form id="login_form" onSubmit={onSubmit}>
                                    <div className="input-element">
                                        <div className="input-group form-group">
                                            <input
                                            type="text"
                                            className="form-control"
                                            placeholder="Email"
                                            {...register("email",{
                                                required: "Email is required*",
                                                maxLength:{
                                                    value:100,
                                                    message:"Maximum 100 charecter allowed*"
                                                },
                                                pattern: {
                                                    value: EMAIL_REGEX,
                                                    message: "Enter a valid email*",
                                                },
                                            })}
                                            />
                                        </div>
                                        {errors.email && <p className="error">{errors.email.message}</p>}
                                    </div>
                                    {/* <div className="form-group forgot_div">
                                        <Link to="/auth/login" className="forgot float-end">Remember your password?</Link>
                                    </div> */}
                                    <div className="form-group">
                                        <input type="submit" disabled={values.disableButton} value="Submit" className="btn float-right login_btn" />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default ForgotPassword;
